:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: #009EE8;
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;
}

@import "/theme/intendit/css/modules/normalize.css";
@import "/theme/intendit/css/modules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/theme/intendit/css/modules/slick.css";
@import "/theme/intendit/css/modules/slick-theme.css";
@import "/theme/intendit/css/modules/menu.css";
@import "/theme/intendit/css/modules/font-awesome.4.5.0.css";
@import "/theme/intendit/css/modules/magnific-popup.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.blue {
    background-color:#009EE8;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid white;
}

.page {
    min-height:80vh;
}

.page ul {
    list-style-type: none;
    margin:0;
    padding:0;
}

    

/* header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    border-bottom:#009EE8 solid 4px;
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:#009EE8;
    z-index:100;
}

.menu .dropdown li a {
    color:#C5ECFF;
}

.menu .dropdown li a:hover {
    color:white;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

.produktkategori img, .artimg img {
    border-radius:8px;
}

/* slider
---------------------------------------------------------*/

.slick-slider {
    margin-bottom:0;
}

.slider-caption-outer .container {
    height: 100%;
    position: relative;
    text-align: left;
    display: inline-block;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:10px;
}

.slick-dots li button:before {
    font-size:0.8rem;
    opacity:1.0;
    color:#fff;
}

.slick-dots li.slick-active button:before {
    color:#009EE8;
}

.slick-dots li {
    border:#D0D0D0 solid 1px;
    border-radius:50%;
    margin:0 2px;
}

.slick-dots li button {
    margin:0;
}

.slider-caption-outer {
    background:rgba(0,0,0,0.4);
}

.slider-caption {
    color:white;
}

.slider-caption h1, .slider-caption h2 {
    font-size:3.0rem;
    color:white;
    margin:0;
}
    

.slider-caption .button {
    background:black;
    border:#101010 solid 1px;
    color:white;
}

.slider-caption .button:hover {
    background:#f2941c;
    border:#f2941c solid 1px;
    color:white;
}

.start-slider{
    height: 500px;
    background-size: cover;
}
    

/* Startbox
---------------------------------------------------------*/

.startbox {
    text-decoration:none;
    color:#111;
}

.startbox article {
    margin-bottom:1.5em;
}

.pop-img img {
    width:100%;
    border-bottom:#029EE8 solid 4px;
}

.startbox-inner {
    padding:0 1.5em;
    display:block;
    position:relative;
}

.block-content {
    color:#111;
}

.block-rub h2 {
    color:#535353;
    font-size:2.0rem;
    font-weight:bold;
}

.boxLink {
    padding-bottom:1.5em;
    margin-top:1.5em;
}

.boxLink .button {
    padding:0.2em 0.6em;
    font-size:0.9rem;
}
    

/* block2
---------------------------------------------------------*/
section.start-block-wrappe2 {
    padding:1.5em 0;
    min-height:5vh;
}

.block2-content {
    text-align:center;
    max-width:900px;
    margin:0 auto;
    width:100%;
}

/* block3
---------------------------------------------------------*/
.start-block-wrapper3 .container > h2 {
    text-align:center;
}

/* block4
---------------------------------------------------------*/
.start-block-wrapper4 {
    color:white;
}

.start-block-wrapper4 h3 {
    color:white;
    font-weight:bold;
}

.start-block-wrapper4 button {
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.start-block-wrapper4 .button, .start-block-wrapper4 button[type=submit], .start-block-wrapper4 input[type=submit] {
    background:black;
    border:black solid 1px;
    color:white;
}

.start-block-wrapper4 .button:hover, .start-block-wrapper4 button[type=submit]:hover, .start-block-wrapper4 input[type=submit]:hover {
    background:#222;
    border:#222 solid 1px;
    color:white;
}

/* block5
---------------------------------------------------------*/
section.start-block-wrapper5 {
    padding:0;
}

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer a {
    color: white;
    text-decoration:none;
}

footer a:hover {
    color:#F28D1A;
}

.socialamedia a {
    font-size:3.0rem;
    padding-right:0.5em;
}

footer h4 {
    font-size:1.8rem;
    color:white;
    font-weight:300;
    margin-bottom:0.5em;
}

.pdflist {
    margin-top:1em;
}

.pdflist-inner {
    padding:1.5em;
}

.pdflist-inner p {
    margin:0;
    font-size:2.0rem;
}

.pdflist-inner ul {
    margin-top:1em;
}


/* other
---------------------------------------------------------*/
.productlist li a {
    display:block;
    width:100%;
    text-align:center;
    text-decoration:none;
    color:#fff
}
.productlist li a:hover {
    color:#fff;
}

.productlist li span {
    display:block;
    padding:0.6em 0;
    background:#009EE8;
}

.productlist li, .productimg {
    position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.productlist li:before, .productlist li:after {
    content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:10px;
    bottom:10px;
    left:0;
    right:0;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}

.productlist li:after {
    right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
    -moz-transform:skew(8deg) rotate(3deg);
    -ms-transform:skew(8deg) rotate(3deg);
    -o-transform:skew(8deg) rotate(3deg);
    transform:skew(8deg) rotate(3deg);
}

.productimg .slick-slide img {
    margin:0 auto;
    display:block;
    text-align:center;
    padding:1em;
}

.price {
    display:block;
    margin-bottom:0.5em;
}

.links {
    margin-top:1.5em !important;
    margin-bottom:1.5em !important;
    background:#eee;
}

.links li {
    padding: 0.5em;
}

.slider-nav  {
    margin-top:0.5em;
}



.boltform textarea {
    min-height:120px;
}

.boltform label {
    display: none;
}

.boltform label {
    display: none;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button, input[type=reset], input[type=submit] {
    margin:0;
    background:#029EE8;
    border:#029EE8 solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, button:hover, input[type=reset]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

button[type=submit], input[type=submit] {
    background:#029EE8;
}

button[type=submit]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

a {
    color:#029EE8;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}
.fa{

    font-size: 120%;
    color: #722f37;
    padding-left:4px;
}

a:hover {
    color:#000;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size:0.9rem;
    color:#282d32;
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    margin:0;
    color:#535353;
}

h1 {
    font-weight:700;
}
.article-img {
    overflow:hidden;
}

.articleSliderSmall {
    width:auto !important;
}

.articleSliderSmall img {
    margin-right:1px;
    border:#C3C3C3 solid 1px;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
    border: none;
    height: 1px;
    background-color: #009EE8;
    clear: both;
}

.kontaktinfo span {
    display:block;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {}

@media (max-width: 750px) {}

@media (max-width: var(--breakpoint-menu)) {
    .menu.show {
        background:white;
    }
    header nav {
        background:#0091E6;
    }
    header .container {
        padding:0;
    }
        

}

@media (min-width: 990px) {
}

@media (min-width: var(--breakpoint-menu)) {
    .slider-caption-outer {
    bottom: 0;
    left: 0;
    overflow: visible;
    position: absolute;
    text-align: center;
    right: 0;
    top: 0;
    }
    .slider-caption {
    position: absolute;
    bottom: 50%;
    width: 100%;
    right: 0;
    text-align: center;
    display: block;
    padding: 0;
    z-index: 3;
    }
}

@media (min-width: var(--breakpoint-xs)) {
    .topmenu {
        margin-top:1.5em;
    }
    .start-block-wrapper4 h3 {
        font-size:2.0rem;
    }
}


@media (max-width: var(--breakpoint-m)) {}
@media (max-width: var(--breakpoint-s)) {}
@media (max-width: var(--breakpoint-xs)) {}
